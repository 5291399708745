<template>
  <gf-content title="Variants" subtitle="List of Product Variants">
    <template #toolbar>
      <el-input style="width: 250px" class="mr-4" v-model="search"></el-input>
      <gf-button type="primary" @click="addVariant">
        <template slot="icon">
          <span class="svg-icon svg-icon-white svg-icon-sm">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/>
              </g>
            </svg>
          </span>
        </template>
        Add a Variant
      </gf-button>
    </template>
    <gf-table :data="variants" v-loading="loading" @row-click="readVariant">
      <el-table-column label="SKU" min-width="180px" prop="sku">
        </el-table-column>
        <el-table-column label="Name" min-width="170px" prop="name">
        </el-table-column>
        <el-table-column label="Buy Price" min-width="130px">
          <template #default="slot">
            {{ (slot.row.prices.find(r => r.price_list_id === 1)) ? (slot.row.prices.find(r => r.price_list_id === 1)).value : null }}
          </template>
        </el-table-column>
        <el-table-column label="Wholesale price" min-width="130px">
          <template #default="slot">
            {{ (slot.row.prices.find(r => r.price_list_id === 2)) ? (slot.row.prices.find(r => r.price_list_id === 2)).value : null }}
          </template>
        </el-table-column>
        <el-table-column label="Retail Price" min-width="130px">
          <template #default="slot">
            {{ (slot.row.prices.find(r => r.price_list_id === 3)) ? (slot.row.prices.find(r => r.price_list_id === 3)).value : null }}
          </template>
        </el-table-column>
    </gf-table>
    <template #footer>
      <gf-pagination :pagination="pagination" @change="getProductVariants"></gf-pagination>
    </template>
  </gf-content>
</template>

<script>
// Services
import ProductService from '@/services/v1/Product'
import ProductVariantService from '@/services/v1/ProductVariant'

// Lodash
import { debounce } from 'lodash'

export default {
  data () {
    return {
      // ui
      loading: false,
      search: '',

      // main
      product: {
        name: null
      },
      optionLabels: [],
      variants: [],

      // Modal
      newVariantVisible: false,
      newVariantButtonLoading: false,
      variant: {
        sku: null,
        name: null,
        initial_cost: null,
        options: []
      },
      stock: {
        warehouse_id: 1,
        value: null
      },
      prices: {
        buy_price: null,
        wholesale_price: null,
        retail_price: null
      },

      // Rule
      variantRule: {
        name: [
          { required: true, message: 'Variant name can\'t be blank', trigger: 'blur' }
        ]
      },

      filter: {
        'pv.is_pack': {
          '=': false
        }
      },

      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      this.getProductVariants()
    }, 400)
  },
  methods: {
    //
    addVariant () {
      this.$router.push({ name: 'product-variant-create' })
    },
    readVariant (row) {
      this.$router.push({ name: 'product-variant-detail', params: { id: this.$route.params.id, vid: row.id } })
    },

    // Modal
    // Supplier Modal
    newVariantSubmit () {
      this.$refs.variant.validate()
        .then(async () => {
          try {
            this.newVariantButtonLoading = true

            this.$sanitize(this.variant)

            this.variant.prices = []
            this.variant.stocks = []
            if (this.prices.buy_price) this.variant.prices.push({ price_list_id: 1, value: this.prices.buy_price })
            if (this.prices.wholesale_price) this.variant.prices.push({ price_list_id: 2, value: this.prices.wholesale_price })
            if (this.prices.retail_price) this.variant.prices.push({ price_list_id: 3, value: this.prices.retail_price })
            if (this.stock.value) this.variant.stocks = [this.stock]

            if (this.variant.prices.length < 1) delete this.variant.prices
            if (this.variant.stocks.length < 1) delete this.variant.stocks

            await this.pdService.createVariant(this.$route.params.id, this.variant)
            this.$message.success(`${this.variant.name} successfully created`)
            this.getProductVariants()

            this.$clearField('variant')
            this.prices.buy_price = null
            this.prices.wholesale_price = null
            this.prices.retail_price = null
            this.stock.value = null
            this.newVariantVisible = false
          } catch (error) {
            this.$error500()
          } finally {
            this.newVariantButtonLoading = false
          }
        })
        .catch(() => {})
    },

    // Fetch
    async getProduct () {
      try {
        const pdService = new ProductService(this.$route.params.id)
        const response = await pdService.get()
        this.product.name = response.data.name
      } catch (error) {
        this.$Error(error)
      }
    },
    async getProductVariants () {
      try {
        this.loading = true

        const pvService = new ProductVariantService(this.$route.params.id)
        const response = await pvService.list(this.pagination.page, null, this.filter)
        this.variants = response.data.rows

        if (response.data.count === 0 && response.data.rows.length !== 0) {
        } else this.pagination.total = response.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.$emit('update:active', 2)

    await this.getProduct()
    await this.getProductVariants()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Inventory' },
      { title: 'Products' },
      { title: this.product.name },
      { title: 'Variants' }
    ])
  }
}
</script>
